





























import { doGetSearchDocument, doPostSecondLevelDocuments } from '@/api/document';


export default {
  data() {
    return {
      searchModel: { title: '' },
      activeName: null as any,
      subLevelList: [] as any[],
      currentDocumentId: null as string | null
    }
  },
  watch: {
    '$store.state.document.secondLevel'(val: string) {
      this.getDocumentSubLevel();
    }
  },
  methods: {
    changeDocument(documentId: string | null, parentId?: string) {
      this.currentDocumentId = documentId;
      let prevDocument: any = {}, nextDocument: any = {}
      if (parentId) {
        // 获取当前的文档列表
        this.$router.replace({ path: '/document', query: { id: documentId, category: this.$store.state.document.secondLevel } }).catch((reason) => console.warn("Avoided: " + reason.message))
        const currentLevel = this.subLevelList.find(item => item.id === parentId);
        if (currentLevel && currentLevel.documents) {
          const documentIndex = currentLevel.documents.findIndex((docu: any) => docu.documentId === documentId);
          if (documentIndex > 0) {
            prevDocument = currentLevel.documents[documentIndex - 1];
          }
          if (documentIndex + 1 < currentLevel.documents.length) {
            nextDocument = currentLevel.documents[documentIndex + 1];
          }
        }
      }
      this.$emit('changeDocument', { documentId, prevDocument, nextDocument })
    },
    async getDocumentSubLevel() {
      const secondLevel = this.$store.state.document.secondLevel;
      let resultData = [];
      try {
        if (secondLevel) {
          const result = await doPostSecondLevelDocuments(secondLevel);
          if (result.data) {
            resultData = result.data.map((item: any, index: number) => {
              const documents = item.documents.map((docu: any) => ({ ...docu, documentId: String(docu.documentId) }));
              if (index === 0) {
                this.activeName = String(item.id)
              }
              return { ...item, id: String(item.id), documents }
            });
          }
        }
      } finally {
        this.subLevelList = resultData;
        const stringfyDocumentList = JSON.stringify(resultData);
        if (resultData.length) {
          if (this.$route.query.id && stringfyDocumentList.includes(`"documentId":"${this.$route.query.id}"`)) {
            this.changeDocument(this.$route.query.id as string, this.activeName);
          } else {
            this.changeDocument(resultData[0].documents.length ? resultData[0].documents[0].documentId : null, this.activeName);
          }
        } else {
          this.changeDocument(null, this.activeName)
        }
      }
    },
    async handleSearch() {
      let resultData = [];
      try {
        const result = await doGetSearchDocument({ documentCategoryLeve2Id: this.$store.state.document.secondLevel, keyword: this.searchModel.title });
        if (result.data) {
          resultData = result.data.map((item: any) => {
            const documents = item.documents.map((docu: any) => ({ ...docu, documentId: String(docu.documentId) }));
            if (!this.activeName) {
              this.activeName = String(item.id)
              this.changeDocument(documents.length ? documents[0].documentId : null, this.activeName);
            }
            return { ...item, id: String(item.id), documents }
          });
        }
      } finally {
        this.subLevelList = resultData;
      }
    }
  }
}
