





































































import { doGetDocumentDetails, likeDocument } from '@/api/document';
import { feedbackDocument } from '@/api/suggest'
import { Form } from 'element-ui';
import dayjs from 'dayjs';

export default {
  props: {
    documentId: {
      type: String,
      default: null
    },
    pagination: {
      type: Object,
      default: () => ({ prevDocument: {}, nextDocument: {} })
    }
  },
  watch: {
    documentId(val: string) {
      this.getDocumentInfo(val)
    }
  },
  data() {
    return {
      showDialog: false,
      feedbackFormModel: {
        title: '',
        description: ''
      },
      documentInfo: {
        likes: 0,
        views: 0,
        documentTitle: '',
        publishDate: '',
        documentContext: '',
        documentCategoryId: ''
      } as { [key: string]: any },
      feedbackFormRules: {
        title: [{ required: true, message: '请填写标题', trigger: 'blur' }],
        description: [{ required: true, message: '请填写描述', trigger: 'blur' }, { max: 100, message: '描述不得大于100个字符', trigger: 'blur' }]
      }
    }
  },
  methods: {
    handleJumpToDocument(documentId?: string) {
      if (documentId) {
        this.$emit('changeDocument', documentId, this.documentInfo.documentCategoryId);
      }
    },
    closeFeedback() {
      this.feedbackFormModel.description = '';
      this.feedbackFormModel.title = '';
      this.showDialog = false;
    },
    confirmFeedBack() {
      const formRefs: Form | null = this.$refs.feedbackFormRef as Form | null;
      if (formRefs) {
        formRefs.validate(async (isValid: boolean) => {
          if (isValid) {
            try {
              const result = await feedbackDocument({ ...this.feedbackFormModel, documentId: this.documentId })
              this.$message.success(result.msg || '提交成功，请耐心等待回复')
              this.closeFeedback()
            } catch (err) {
              this.$message.error(err as string || '提交失败')
            }
          }
        })
      }
    },
    async getDocumentInfo(documentId: string | null) {
      if (documentId) {
        const result = await doGetDocumentDetails(documentId);
        if (result.data) {
          Object.keys(this.documentInfo).forEach(key => {
            this.documentInfo[key] = result.data[key] || this.documentInfo[key]
            this.documentInfo.publishDate = result.data.publishDate ? dayjs(result.data.publishDate).format('YYYY-MM-DD HH:mm:ss') : '';
          })
        }
      } else {
        Object.keys(this.documentInfo).forEach(key => {
          this.documentInfo.likes = 0,
            this.documentInfo.views = 0,
            this.documentInfo.documentTitle = '',
            this.documentInfo.publishDate = '',
            this.documentInfo.documentContext = '',
            this.documentInfo.documentCategoryId = ''
        })
      }
    },
    async handleLikeDocument() {
      try {
        const result = await likeDocument(this.documentId);
        this.$message.success(result.msg || '点赞成功');
      } catch (err) {
        this.$message.error(err as string || '点赞失败');
      }
    }
  }
}
