







import DocumentContent from './DocumentContent.vue';
import DocumentLevel from './DocumentLevel.vue';
export default {
  components: { DocumentLevel, DocumentContent },
  data() {
    return {
      currentDocumentId: null as string | null,
      pagination: {
        prevDocument: {},
        nextDocument: {} 
      },
      key: 0
    }
  },
  methods: {
    changeDocument(documentOptions: { documentId: string | null, prevDocument: any, nextDocument: any }) {
      this.currentDocumentId = documentOptions.documentId;
      this.pagination.prevDocument = documentOptions.prevDocument || {};
      this.pagination.nextDocument = documentOptions.nextDocument || {}
      // this.key = Date.now()
    },
    contentChangeDocument(documentId: string, documentCategoryId: string) {
      const documentLevelRef: InstanceType<typeof DocumentLevel> | null = this.$refs.documentLevelRef as InstanceType<typeof DocumentLevel> | null;
      if (documentLevelRef) {
        documentLevelRef.changeDocument(documentId, documentCategoryId)
      }
    }
  },
  mounted() {
    const queryDocumentId = this.$route.query.id;
    if (queryDocumentId) {
      this.currentDocumentId = queryDocumentId as string;
    }
  }
}
